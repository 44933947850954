import Styling from "./Styling.module.css";
import ContentCarosel, {items} from "../../contentCarousel/contentCarosel";
import HtmlTextConverter from './projectFiles/htmlTextConverter';
import Puzzle from "./projectFiles/AI/PuzzleSolver";
import TicTacToe from './projectFiles/AI/TickTacToe';
import BackwardChaining from './projectFiles/AI/BackwardChaining';
import CaphalopodGame from "./projectFiles/DSA/CephalopodGame";
import CatalogueSale from "./projectFiles/DSA/CatalogueSale";
import BarChart, {SubjectItem} from "../../barChart/barChart"
import SubimageLocate from "./projectFiles/DSA/SubimageLocate";
import TitleWrapper from "../../TitleWrapper/TitleWrapper";
import { stateItems } from "../../../BaseProject/Components/contentSliders/contentSliderBottom";
import DirEncryption from "./projectFiles/Others/DirEncryption";
import FtpBackup from "./projectFiles/Others/Ftp_Backup";
import Suduku from "./projectFiles/DSA/Suduku";
import Nineboard_TicktacToe from "./projectFiles/AI/NineBoard_Ticktactoe";
import File_Processing from "./projectFiles/Unix/File_Processing";
import IO_Simulation from "./projectFiles/Unix/IO_Simulation";
import WebsiteDemo_1 from "./projectFiles/Websites/WebsiteDemo_1";
import BarChartDemo from "./projectFiles/React/BarChartDemo/BarChartDemo";
import ContentCaroselDemo from "./projectFiles/React/ContentCarouselDemo/ContentCaroselDemo";
import AnimationBanner_Demo from "./projectFiles/React/AnimationBanner/AnimationBanner_Demo";
import WebsiteDemo_2 from "./projectFiles/Websites/WebsiteDemo_2";
import ShoppingApplication from "./projectFiles/FullStack/ShoppingApplication";
import ShoppingSystem from "./projectFiles/FullStack/VendManagement"

let RenderPage : React.FC<{}> = (props) => { 

    let All_Projects : items[] = [
        ShoppingSystem,
        ShoppingApplication,
        // WebsiteDemo_1,
        WebsiteDemo_2,
        BarChartDemo,
        AnimationBanner_Demo,
        ContentCaroselDemo,
        IO_Simulation,
        File_Processing,
        Nineboard_TicktacToe,
        Suduku,
        FtpBackup,
        DirEncryption,
        SubimageLocate,
        Puzzle,
        TicTacToe,
        BackwardChaining,
        CaphalopodGame,
        CatalogueSale,
    ];

    let AI_Projects : items[] = [
        BackwardChaining,
        TicTacToe,
        CaphalopodGame,
        Nineboard_TicktacToe,
        Puzzle
    ];


    let DSA_Projects : items[] = [
        SubimageLocate,
        CatalogueSale,
        Suduku,
        FtpBackup,
        
    ];
    
    let OS_Projects: items[] = [
        File_Processing, 
        IO_Simulation,
        DirEncryption
    ];

    let React_Projects : items[] = [
        AnimationBanner_Demo,
        ContentCaroselDemo,
        BarChartDemo,
    ]


    let Website_Projects : items[] = [
        ShoppingApplication,
        WebsiteDemo_1,
        WebsiteDemo_2
    ]

    let FullStack_Projects : items[] = [
        ShoppingSystem,
        ShoppingApplication,
    ]






    let CItems : SubjectItem[] = [
        {
            Name : "All Projects",
            CategoryItems : 
            <>  
                <ContentCarosel   TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"All Prouects"} Description = {"A selection of recent projects."} Items={All_Projects}/>
            </>
        },

        {
            Name : "FullStack",
            CategoryItems : 
            <>  
                <ContentCarosel   TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"Fullstack proj"} Description = {"A selection of recent projects."} Items={FullStack_Projects}/>
            </>
        },  
        {
            Name : "AI",
            CategoryItems : 
            <>  
                <ContentCarosel   TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"AI Projects"} Description = {"A selection of recent projects."} Items={AI_Projects}/>
            </>
        },
        {
            Name : "DSA",
            CategoryItems : 
            <>  
                <ContentCarosel  TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"DSA Projects"} Description = {"A selection of recent projects."} Items={DSA_Projects}/>
            </>
        },
        {
            Name : "Website",
            CategoryItems : 
            <>  
                <ContentCarosel  TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"websites "} Description = {"A selection of recent projects."} Items={Website_Projects}/>
            </>
        },
        {
            Name : "Operating Systems",
            CategoryItems : 
            <>  
                <ContentCarosel  TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"Operating Systems Projects"} Description = {"A selection of recent projects."} Items={OS_Projects}/>
            </>
        },
        {
            Name : "React Components",
            CategoryItems : 
            <>  
                <ContentCarosel  TitleStyling={ {color:'white'}} contentHeight={2} popup_ZIndex={1000} Title ={"React Components"} Description = {"A selection of recent projects."} Items={React_Projects}/>
            </>
        },

    ]

    let test : items[] = [
        ShoppingSystem,
        ShoppingApplication,
        WebsiteDemo_2,
        AnimationBanner_Demo,
        ContentCaroselDemo,
        BarChartDemo,
        WebsiteDemo_1,
        IO_Simulation,
        File_Processing,
        Nineboard_TicktacToe,
        Suduku,
        FtpBackup,
        DirEncryption,
        SubimageLocate,
        Puzzle,
        TicTacToe,
        BackwardChaining,
        CaphalopodGame,
        CatalogueSale,
    ];




    return(
        <>
            <div className = {Styling.projectContainer}>
                <TitleWrapper>
                    <p className="sectionTitle">Projects</p>
                </TitleWrapper> 
                <div style = {{width:'100%', marginTop:"10px"}}>
                    <BarChart SubjectItems = {CItems}/> 
                </div>
            </div>
        </>
    );
}


export default RenderPage;