
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import DynamicHeading from '../Others/dynamicText';
import {ObjectRef} from '../../../Interfaces/RefObjectInterface';
import StateObject,{StateWrapper} from '../../../Interfaces/stateObjectInterface';
import myBrowser from '../../../Handlers/myBrowser';
import { JsxElement } from 'typescript';
import ScrollableFrame from '../Others/ScrollableFrame';
import AnimationBar from '../../animations/AnimationBar/animationBar';
import Styling from './styling.module.css';
import GridFrame from '../Others/gridFrame';
import { channel } from 'diagnostics_channel';
import ImageFrame from '../Others/imageFrame';
import SimpleButton from '../../buttons/simpleButton2/simpleButton2';
import ToggleButton from '../../buttons/ToggleButton/ToggleButton';
import { title } from 'process';
import Stack from '../../../DataStructures/Stack';
import FrameLayout from "../FrameLayout/FrameLayout"

/* ------------------------------------------ Component Description ------------------------------------------
    Renders a bar-chart displaying given items and animated-percentage Bar in association with each item. 

    This component works in conjunction with the barChartHander class to store state values, and to 
    coordinate the items to be displayed.  
    Bar-Chart has 3 main sections, this is :
        1) SubjectItems
        2) CategoryItems
        3) Elements 
    where Elements ∈ CategoryItems ∈ CategoryItems, As such : the selected SubjectItem will dictate the type
    of CategoryItems, and hence elements to be displayed on the bar-Chart.
    
    Hence, this component takes as props :
        SubjectItems : SubjectItem[]; The array of SubjectItems each containing its own distinct set of
                       CategoryItem and respective elements to be rendered. 
                       
    Note : Current Component only support |SubjectItems| = 2.  
------------------------------------------------------------------------------------------------------------*/
export interface SubjectItem
{
    Name : String,
    CategoryItems : CategoryItem[];
}
/* CategoryItem ⊆ SubjectItems */
export interface CategoryItem {
    Title : string,
    elements :  { 
        name : String,
        percentage : number
        color_Theme : string
    }[],
}

let k:Stack<number> = new Stack();
/* Global Bar-Chart Handler */
let RenderBarChart : React.FC<{SubjectItems : SubjectItem[]}> = (props) : React.ReactElement => 
{    
    let [selected_Categoryitem, set_CategoryItem] = useState<CategoryItem | undefined>(props.SubjectItems[0].CategoryItems[0]);
    let [itemHovered, set_ItemHovered] = useState<String | undefined> (undefined);
    let moreInfoRef = useRef<HTMLDivElement>(null);


    useLayoutEffect(
        () => {
            let mouseMoveHandler = (event :MouseEvent) => { 
                let mouseX = event.clientX, mouseY = event.clientY;

                if (moreInfoRef.current != null){
                    moreInfoRef.current.style.top = `${mouseY-11}px`;
                    moreInfoRef.current.style.left = `${mouseX-5}px`;
                }
            }

            if (itemHovered != undefined){
                window.addEventListener("mousemove", mouseMoveHandler);
            }

            return () => { 
                window.removeEventListener("mousemove", mouseMoveHandler);
            }
        }
    );

    useLayoutEffect(
        () => {modifyLayout();}
    )
    
    /**
     * Check if content exist on either side
     * @param direction 
     * @returns 
     */
    let hasContent = (direction:String) => { 
        if (titleContainer_Ref.current != undefined && ToptitleContainer_Ref.current != undefined){ 
            let node = titleContainer_Ref.current as HTMLDivElement;
            let childs = node.children;
            let firstChild = childs[0] as HTMLElement;                
            let lastChild = childs[childs.length-2] as HTMLElement;                

            if (direction === "left")  return  (( firstChild.offsetLeft) < titleOffset.current) ;
            else return (lastChild.offsetLeft + lastChild.offsetWidth) > (titleOffset.current +  node.clientWidth );
        }
        return false;
    }
    
    let modifyLayout = () => { 
        if (titleContainer_Ref.current != undefined && ToptitleContainer_Ref.current != undefined && rightArrow_Ref.current != undefined && leftArrow_Ref.current != undefined){
            let hasLeft = hasContent("left"), hasRight = hasContent("right");

            if (hasLeft && hasRight)   {
                // ToptitleContainer_Ref.current.style.gridTemplateColumns = "2% 96% 2%";
                titleContainer_Ref.current.style.borderRight = "0.5px solid grey";
                titleContainer_Ref.current.style.borderLeft = "0.5px solid grey";
                rightArrow_Ref.current.style.width = "30px";
                leftArrow_Ref.current.style.width = "30px";
            } 
            else if (hasLeft){
                // ToptitleContainer_Ref.current.style.gridTemplateColumns = "2% 98% 0%";
                titleContainer_Ref.current.style.borderLeft = "0.5px solid grey";
                titleContainer_Ref.current.style.borderRight = "none"; 
                rightArrow_Ref.current.style.width = "0px";
                leftArrow_Ref.current.style.width = "30px"; 
            }   
            else if (hasRight) {
                // ToptitleContainer_Ref.current.style.gridTemplateColumns = "0% 98% 2%";
                titleContainer_Ref.current.style.borderRight = "0.5px solid grey";
                titleContainer_Ref.current.style.borderLeft = "none"; 
                rightArrow_Ref.current.style.width = "30px";
                leftArrow_Ref.current.style.width = "0px";
            }                
            else{
                // ToptitleContainer_Ref.current.style.gridTemplateColumns = "0% 100% 0%";
                titleContainer_Ref.current.style.borderLeft = "none";
                titleContainer_Ref.current.style.borderRight = "none"; 
                rightArrow_Ref.current.style.width = "0px";
                leftArrow_Ref.current.style.width = "0px";
            }                       

        }
    }

    useLayoutEffect(
        () => {
            let resizeHandler = () => { 
                set_CategoryItem(props.SubjectItems[0].CategoryItems[0]);
                modifyLayout();
            }

            window.addEventListener("resize", resizeHandler);
            return () => {
                window.removeEventListener("resize", resizeHandler);
            }
        },
    )

    useLayoutEffect(
        ()=>{
            transition("right", selected_Categoryitem?.Title);
        },[selected_Categoryitem]
    )

    
    let titleContainer_Ref = useRef<HTMLDivElement>(null);
    let titleOffset = useRef<number> (0);
    let rightArrow_Ref = useRef<HTMLDivElement>(null);
    let leftArrow_Ref = useRef<HTMLDivElement>(null);
    let firstElement = useRef<String | undefined>(selected_Categoryitem?.Title);

    let transition = ( direction : String, itemName ?:String ) => { 
        // titlecontainer width
        let width = titleContainer_Ref.current?.clientWidth;
        if (width != undefined){
            if (titleContainer_Ref.current != undefined){
                let node = titleContainer_Ref.current as HTMLDivElement; // title container node
                let childs = node.children;                              // titles 
                // iterate titles
                for (let i =0; i < childs.length; i++) {
                    let child = childs[i] as HTMLElement;
                    
                    if (itemName != undefined)
                    {
                        // if title is selected
                        if (child.innerText === itemName){
                            titleOffset.current = child.offsetLeft-20;
                            break;
                        }
                    }
                    else if ( ( (child.offsetLeft + child.offsetWidth) - titleOffset.current) > node.clientWidth && child.innerText != ""){
                            // console.log("overflow item is : " + child.innerText);
                            // if (child.offsetWidth >= width) titleOffset.current = child.offsetWidth;
                            // else 
                            if (firstElement.current == child.innerText){
                                titleOffset.current += child.offsetWidth;
                            }else{
                                titleOffset.current = child.offsetLeft;
                                firstElement.current = child.innerText;
                            }
                            break;
                    }
                }



            if (direction === "right") {
                titleContainer_Ref.current.scrollTo({
                    left: titleOffset.current,
                    behavior: "smooth"
                });
            }
            else {
                titleOffset.current = 0;
                titleContainer_Ref.current.scrollTo({
                    left: 0,
                    behavior: "smooth"
                });
            } 


                modifyLayout();
            }
        }
    }


    let ToptitleContainer_Ref = useRef<HTMLDivElement>(null);

    return (
        <>
            <div style = {{width:'100%', position:'relative',}}>
                <div ref ={moreInfoRef} className={Styling.overlayTitle} style = {{ color:'white', fontSize:'12px', transform: 'translate(-50%,-100%)',  display:`${itemHovered != undefined ? "block" : "none"} `}}> 
                    { itemHovered != undefined && <> { itemHovered } </> }
                    
                </div>
                <div ref = {leftArrow_Ref}  onMouseDown={() => { transition("left")}} className={Styling.arrowContainer} style = {{ transform :'translate(-100%,0%)', left:'0'}} >
                    <svg fill="black" height="100%" width="100%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 330.002 330.002" stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_227_" d="M233.25,306.001L127.5,165.005L233.25,24.001c4.971-6.628,3.627-16.03-3-21c-6.627-4.971-16.03-3.626-21,3 L96.75,156.005c-4,5.333-4,12.667,0,18l112.5,149.996c2.947,3.93,7.451,6.001,12.012,6.001c3.131,0,6.29-0.978,8.988-3.001 C236.878,322.03,238.221,312.628,233.25,306.001z"></path> </g></svg>
                </div>
                <div ref = {rightArrow_Ref}  onMouseDown={()=>{transition("right")}} className={Styling.arrowContainer} style = {{right:'0', transform :'translate(100%,0%)',}} > 
                    <svg fill="black" height="100%" width="100%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"  stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"></path> </g></svg>
                </div>
                    
                <div ref ={ToptitleContainer_Ref} className={Styling.title_TopContainer}>
                    <div ref = {titleContainer_Ref} className={Styling.titleContainer} > 
                        {
                            props.SubjectItems[0].CategoryItems.map(
                                (item : CategoryItem) => {
                                    let isSelected : boolean =  (item.Title === selected_Categoryitem?.Title);

                                    return <>
                                        <span 
                                            className= {Styling.itemTitle}
                                            style = {{paddingBottom:'9px', borderBottom: `${isSelected ? "solid 2px black" :"solid 0.5px black"}`,   cursor:'pointer',  color : `${ (isSelected) ? "black" : "grey" }`, fontWeight: `${(isSelected) ? "500" : "300"}`}}
                                            onMouseDown={() => {set_CategoryItem(item); transition("right", item.Title) }}>{item.Title}
                                        </span>
                                        <span 
                                            className= {Styling.itemTitle}
                                            style = {{paddingRight:"15px", paddingBottom:'9px', borderBottom: "solid 0.5px black",   cursor:'pointer',  color :"grey", fontWeight: `${(isSelected) ? "500" : "300"}`}}
                                            >
                                        </span>
                                    
                                    </>;
                                }
                            )
                        }
                    </div>      
                    {/* <div  ref = {rightArrow_Ref} onMouseDown={()=>{transition("right")}} className={Styling.arrowContainer}> 
                        <svg fill="#ffffff" height="100%" width="100%" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330"  stroke="#ffffff"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_222_" d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001 c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213 C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606 C255,161.018,253.42,157.202,250.606,154.389z"></path> </g></svg>
                    </div> */}
                </div>
                
                <div className={Styling.mainContainer}>
                    <div className={Styling.innerContainer}>
                        {/* <div style = {{width:'100%', background:'blue'}}> kkk </div>
                        <div style = {{width:'100%', background:'blue'}}> kkk </div> */}
                        {
                            (selected_Categoryitem != undefined) && <> 
                                {
                                    selected_Categoryitem.elements.map( (item : {name : String, percentage : number, color_Theme:string}) => {
                                            return <>  
                                                <div className={Styling.ElementContainer} onMouseOver={(event : React.MouseEvent) => {set_ItemHovered(item.name)}} 
                                                    onMouseLeave={ (event:React.MouseEvent) =>{ set_ItemHovered(undefined)}} >
                                                    <div className={Styling.itemNameContainer}>
                                                        <div className={Styling.itemName} >
                                                            <p> {item.name}</p>
                                                        </div>
                                                    </div>
                                                    <div style = {{width:'100%', height :"60px"}}>
                                                        <AnimationBar percentage={item.percentage} color_Theme={item.color_Theme} />
                                                    </div>
                                                </div> 
                                            </>   
                                        }
                                    )
                                }
                            </>
                        }
                    </div>
                </div>
            </div>
        </> 
    )
}
export default RenderBarChart;