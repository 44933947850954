import  {items} from '../../../../contentCarousel/contentCarosel';
import DemoWrapper from "../../../../DemoWrapper/DemoWrapper";
import React, { MouseEvent, useRef } from 'react';
import IframeWrapper from "../../../../IFrame_Container/IFrameContainer";


let CaroselItem : items =    {
    key : 1,
    navigationUrl : "#demo_Project",
    Subject : "Website Demo 1",
    Description : 
        <>
            <p style = {{marginBottom:'50px'}}>
                A demonstration and usage of the components 1) Animation Banner and 2) Barchart, plus additional logic which handles transition from banner to 
                content of the page when scroll-wheel is evoked. 
            </p>
            {/* <DemoWrapper Title={"Demo Project"}>
                <div style = {{width:'100%', height :'550px', overflow:"hidden"}}>
                    <IframeWrapper PageURL={"#demo_Project"}/>
                </div>
            </DemoWrapper>  */}
        </>,
    img_Url:"./images/Project_Images/webDemo.jpg", 
    show_Image: false,           
    file_List : [
        {
            key : 1,
            FileTitle : "",
            content : ""
        }
    ]
}

export default CaroselItem;