import ScrollableFrame from '../../BaseProject/Components/layouts/Others/ScrollableFrame';
import ImageFrame from '../../BaseProject/Components/layouts/Others/imageFrame';
import styling from './styling.module.css';
import myBrowser from '../../BaseProject/Handlers/myBrowser';
import {StateWrapper} from '../../BaseProject/Interfaces/stateObjectInterface';
import React, { CSSProperties, ReactElement, ReactText, useEffect, useLayoutEffect, useRef, useState } from 'react';
import AppleCloseButtom from '../../BaseProject/Components/buttons/closeButtonApple/CloseButtonApple';
import {ObjectRef} from '../../BaseProject/Interfaces/RefObjectInterface';
import CodeWindow, {WindowState, FileList} from '../../BaseProject/Components/layouts/codeDemoWindow/CodeWindow';
import { reduceEachTrailingCommentRange } from 'typescript';
import DynamicText from "../../BaseProject/Components/layouts/Others/dynamicText";
import FrameLayout from "../../BaseProject/Components/layouts/FrameLayout/FrameLayout";
import ImageLoader from "../../Components/ImageLoader/ImageLoader";

// import DynamicStringHandler from '../Dynamic_StringContent/DynamicString'; 

/* ------------------------------------------ Component Description ------------------------------------------
    Renders a content carosel component inheriting the full width,height of the parenting component. Content 
    is rendered dynamically based on these values.

    The contents of the elements given will utilise the 'Dynamic_StringContent' class to format / structure a
    string of text into its HTMLElement (with styling applied), this is applied to the content description 
    (i.e. props.Description).

    The content_Carosel has two important properties that must be satisfied, this is :
        1)  Parent component must have min-height of 1000px, else overflow will occur. 
        2)  Parent component must have well-defined width, height. 

    The Component take as arguments through props  : 
        Text : String 
        Styling : React.CSSProperties 
        onChangeAction : onChange callback action handler
        isSimpleContainer : boolean, s.t. isSimpleContainer = true IFF its direct parent container and styling given by props
                            is not a flex-box nor grid-box, else isSimpleContainer = false.  This is set to false by default. 
       popup_ZIndex : z_index specified for popup.  
    Note : The following conditions must be met for components proper functionality 
        1) Padding in direct parent container is not yet supported by this compoment, we use additional div-wrapping as solution.           
        2) Direct parentNode container must inherit the overflow = hidden property to ensure proper container scalling values 
           conducted in --> (Ref : 2107)
        3) isSimpleContainer must be specified IFF parent container OR styling given is : 
                A) A flex box
                B) Grid Box
------------------------------------------------------------------------------------------------------------*/

/** position elements after currentRow change (or on rescale) */
let positionElements = (contentContainer_Ref :React.RefObject<HTMLDivElement>, contentSize: number, currentRowWrapper: StateWrapper<number>, Horizontal_ElementSize: number ) => {
    if (contentContainer_Ref.current!= undefined)
    {
        let x = contentSize * currentRowWrapper.item;
        contentContainer_Ref.current.style.transform = `translate(0,-${x+0.4}px)`;
    }
}

/** Handle Carosel element Click : Open code window */
let Toggle_CodeWindow = (event :React.MouseEvent | undefined, action : String,  Horizontal_ElementSize :number,  Items : items[], window_States : WindowState[], overlay_Wrapper : StateWrapper<{ item : items, visible : boolean, windowState?: WindowState | undefined } | undefined>) => {    
    switch(action){
        case "close" : 
            document.body.style.overflowY = "auto"; //  default page scroll
            console.log("window closing!");
            overlay_Wrapper.setItem(undefined); 
        break;
        case "open" :
            document.body.style.overflowY = "hidden"; // stop default page scroll
            if (event != undefined){
                let item : HTMLDivElement = event.currentTarget as HTMLDivElement;
                let itemNumber : number  = parseInt( ( (item.dataset.key != null) ? item.dataset.key : "-1"));
                let state : WindowState | undefined;

                if (itemNumber != -1) {
                    state = window_States[itemNumber];
                }
                console.log("window " + itemNumber + " has been opened");
                overlay_Wrapper.setItem({item : Items[itemNumber],  visible : true, windowState :state}); 
            }
        break;
   }
}

export interface items {
    key : number;
    Subject : String;
    Description : React.ReactNode;
    tag ? : String[];
    img_Url : String;
    file_List : FileList[];
    navigationUrl ?: String;
    show_Image ?: boolean;
}

let ContentCarosel : React.FC<{ isdarkNode ?: boolean,  Items : items[], contentHeight : number, Title : String, Description :String, popup_ZIndex : number, TitleStyling? : React.CSSProperties}> = (props) => { 
    
    let Horizontal_ElementSize = useRef<number>(0);
    let spacing : number = 1; // gap percentage between elements
    let total_elementSize = props.Items.length;  
    let content_BoxSizing :number = (100/Horizontal_ElementSize.current) ;              
    let contentContainer_Ref  = React.useRef<HTMLDivElement>(null);
    let container_Ref = React.useRef<HTMLDivElement> (null);
    let contentWrapper_Ref = React.useRef<HTMLDivElement>(null);
    let [currentRow, set_currentRow] = React.useState<number>(0); 
    let [contentSize, set_contentSize] = useState<number>(0);
    let [contentContainer_Sizing, set_contentContainerSizing] = useState<{w : number, h : number}>( {w:0, h :0});
    let currentRowWrapper :StateWrapper<number> = {
        item : currentRow,
        setItem : set_currentRow       
    }
    let [overlayState, set_OverlayState] = useState<{item : items, visible : boolean, windowState?: WindowState}> ();
    let overlayState_Wrapper : StateWrapper<{ item : items, visible : boolean, windowState?: WindowState } | undefined> = {
        item : overlayState,
        setItem : set_OverlayState,
    } 
    let max_VerticalSizing = props.contentHeight;
    let [globalFontSize, set_GlobalFontSize] = useState<number> (2); // global FontSize. 
    let Dynamic_globalFontRef:ObjectRef<HTMLParagraphElement> ={
        setFunc(ref, toRef) {
            if (toRef.current != undefined) {
                ref.ref = toRef;
            }
        },
        ref : undefined
    }
    /** CodeWindow initialisation & States, that is : the CodeDemo component */
    let CodeWindow_Elements : { windowStates:WindowState[], windowElement : React.ReactElement[] } = React.useMemo( () => { 

        let states :{ windowStates:WindowState[], windowElement : React.ReactElement[] }  = {
            windowStates : [],
            windowElement : []
        };

        for (let i=0; i < props.Items.length; i++)
        {
            states.windowStates.push( {windowState :0, setWindowState : undefined});     // Initialise all codeWindow to closed. 
            states.windowElement.push( <CodeWindow popup_ZIndex={(props.popup_ZIndex+1)} Window_State = {states.windowStates[i]} Title = {props.Items[i].Subject} fileList = {props.Items[i].file_List} />);
        }
        
        return states;

    }, props.Items);


    useEffect(
        ()=> { 
            set_currentRow(0);
        },[props.Title]
    )

    /** Listeners setup */
    useLayoutEffect( 
        ()=>{
            // let lastWidth = window.innerWidth; // Store the initial width

            let timeout_ID: NodeJS.Timeout | null = null;

            // rescale carosel content sizing
            const re_scale = () => {
                if (myBrowser.isMobileWidth())                      Horizontal_ElementSize.current = 2;
                else if (myBrowser.getBrowserWidth() < 1500)        Horizontal_ElementSize.current = 4;
                else                                                Horizontal_ElementSize.current = 5;
                
                /** Dynamically rescale container & element */
                if (container_Ref.current != undefined){
                    let container_Width = container_Ref.current.clientWidth;                                // current container width
                    let new_ContainerState = {w: container_Width, h: 0};                // set new container size
                    let new_ElementSize = (new_ContainerState.w / Horizontal_ElementSize.current)-1;      // set new element size to fit container

                    // calculate container heigh size [MAKE MORE EFFICIENT]
                    for (let i =1 ; i <= max_VerticalSizing ; i++) {
                        if (Horizontal_ElementSize.current * i >= props.Items.length || i == max_VerticalSizing ){
                            new_ContainerState.h = i * new_ElementSize;
                            break;
                        }
                    }

                    // initialise container size
                    if (new_ContainerState.w != contentContainer_Sizing.w || new_ContainerState.h != contentContainer_Sizing.h) {
                        set_contentContainerSizing(new_ContainerState);
                    }
                    // initialise element size
                    if (new_ElementSize != contentSize) set_contentSize(new_ElementSize);
                 }
            };

            re_scale();
            
            const rescale_Handler = () => {
                // if (lastWidth === window.innerWidth) return;


                // lastWidth = window.innerWidth;
                if (timeout_ID) {
                  clearTimeout(timeout_ID); // cancel previous setTimeout Call if it has not been executed.
                }
                
                timeout_ID = setTimeout(re_scale, 1 ); // initialise setTImeout call
            };
            
            rescale_Handler();
            window.addEventListener("resize", rescale_Handler);
            return () => {
                window.removeEventListener("resize", rescale_Handler);
                if (timeout_ID) clearTimeout(timeout_ID);                           // previous setTimeout Call
            }
        },[contentSize, contentContainer_Sizing]
    )

 


    /** Reposition elements on re-render */
    useLayoutEffect(
        () =>{ 
            positionElements(contentWrapper_Ref, contentSize, currentRowWrapper, Horizontal_ElementSize.current);
         }
    );

    /** 
     * Iterate to a new row by settingState to new row value
     * @direction : 1) "forward", 2) "backward"; to specify which direcition to iterate. 
     */
    let iterateRow = (event :React.MouseEvent, direction : String,  total_ElementSize : number, ElementSize : number,  Horizontal_ElementSize : number, contentWrapper_Ref : React.RefObject<HTMLDivElement> ) => {
        if (contentWrapper_Ref.current != undefined) 
        {
                let totalnumber_Rows = Math.ceil(total_ElementSize / Horizontal_ElementSize); 
                let prev_RowSize = currentRow;
                let activeRowsize = Math.floor(contentWrapper_Ref.current.clientHeight / ElementSize); // currently active rows 
                let rowsOverflown = ((totalnumber_Rows - prev_RowSize) - activeRowsize) ;
                
                if (direction === "forward")
                {
                    if (rowsOverflown > 0 )
                    {
                        console.log( rowsOverflown + " row is overflown");
                        set_currentRow(currentRow + activeRowsize);
                    }
                }else if (direction === "backward"){
                    let decrement_Val = currentRow - activeRowsize;
                    if (decrement_Val < 0) decrement_Val = 0;
                    set_currentRow(decrement_Val);
                }
        }
    }

    let viewMore_Handler = (event :React.MouseEvent, direction : String ) => {
        iterateRow( event, direction, total_elementSize, contentSize, Horizontal_ElementSize.current, contentContainer_Ref);
    }

    let dynamicFontChange_Handler = () => {
        if (Dynamic_globalFontRef.ref != undefined){
            let fontSize = myBrowser.RefHandling.getRefFontSize(Dynamic_globalFontRef.ref);
            console.error("GFS = " + fontSize)
            set_GlobalFontSize(fontSize < 1 ? 1 : fontSize);
        }
    }


    /** Open code window handler */
    let OpenCodeWindow = () => {

        if (overlayState != undefined) {
            if (overlayState.windowState?.setWindowState != undefined) {
                overlayState.windowState.setWindowState(1);
            }
        }
    }


    // custom styling
    const PageQuery : String = `
        @media only screen and (max-width: ${myBrowser.mobile_Width()}px) {
            #contentOverlay_Wrapper{    
                flex-direction: column;
                align-items: center;
            }

            #sticky_Container{
                display : none;
            }

            #overlayDescription{
                width:100% !important;
                padding-left: 0%;
                padding-right: 0%;
                text-align: center;
                font-size:12px;

            }

            h1{
                font-size:20px;
            }


        }

        @media only screen and (max-width: ${1600}px) {
            #overlayDescription{
                width:90% !important;
                // min-width: 800px;
                text-align: left;
            }
        }
        
        @media only screen and (min-width: ${1600}px) {
            #overlayDescription{
                width:70% !important;
                min-width: 800px;
                text-align: left;
            }
        }

        


        @media only screen and (min-width: ${myBrowser.mobile_Width()}px) {
            #contentOverlay_Wrapper{
                flex-direction: row;
            }

            #sticky_Container{
                display : block;
            }

            // #overlayDescription{
            //     width:70% !important;
            //     padding-left: 2%;
            //     min-width: 800px;
            //     text-align: left;
            // }
        }      
    `;

    return(
        <>
            <style> {PageQuery} </style>
            { CodeWindow_Elements.windowElement /** Render WindowElements */}
            { // RENDER SELECTED CODE WINDOW 
                (overlayState!= undefined && overlayState.visible) &&
                <div className={styling.codeWindow_Container} style = {{zIndex:`${props.popup_ZIndex}`}}>
                                {/* <div className= {styling.close_OverlayButtonContainer} onMouseDown={() => {Toggle_CodeWindow(undefined, "close",  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 50 50" fill='white'>
                                        <path d="M25,2C12.319,2,2,12.319,2,25s10.319,23,23,23s23-10.319,23-23S37.681,2,25,2z M33.71,32.29c0.39,0.39,0.39,1.03,0,1.42	C33.51,33.9,33.26,34,33,34s-0.51-0.1-0.71-0.29L25,26.42l-7.29,7.29C17.51,33.9,17.26,34,17,34s-0.51-0.1-0.71-0.29	c-0.39-0.39-0.39-1.03,0-1.42L23.58,25l-7.29-7.29c-0.39-0.39-0.39-1.03,0-1.42c0.39-0.39,1.03-0.39,1.42,0L25,23.58l7.29-7.29	c0.39-0.39,1.03-0.39,1.42,0c0.39,0.39,0.39,1.03,0,1.42L26.42,25L33.71,32.29z"></path>
                                    </svg>
                                </div> */}
                    <div className={styling.overlayScaling_Container}>
                        <ScrollableFrame>
                            <div id = "contentOverlay_Wrapper" className={styling.contentOverlay_Wrapper}>
                                <div id = "overlayDescription" style = {{  boxSizing:"border-box", wordBreak:"break-all"}}>
                                    <div  className={styling.StickyDescription_Container} style = {{position:'sticky', top:'0', left:'0', zIndex:'9999'}}>

                                        <h1 style = {{margin:'0', marginBottom:'10px', textAlign:'center'}}> {overlayState.item.Subject} </h1>
                                        <div style = {{width:'100%', boxSizing:'border-box', textAlign:'center', marginBottom:'10px'}}>
                                                <div className={styling.ActionContainer}   onMouseDown={() => { Toggle_CodeWindow(undefined, "close",  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)}}> 
                                                    <span> Back </span>
                                                </div>
                                                {
                                                    // IF CODE DEMO
                                                    overlayState.item.navigationUrl === undefined &&
                                                        <div className={styling.ActionContainer} style = {{borderLeft:"1px solid black"}} onMouseDown={ () => { OpenCodeWindow() }} > 
                                                            View Code
                                                        </div>
                                                    // IF LINK DEMO
                                                    ||
                                                        <div className={styling.ActionContainer} style = {{borderLeft:"1px solid black"}} onMouseDown={ () => {window.open(overlayState!.item.navigationUrl!.toString(), '_blank', 'noopener,noreferrer,width=1500,height=900'); }} > 
                                                            Live Demo
                                                        </div>
                                                }
                                        </div> 
                                    </div>
                                    {
                                        overlayState.item.show_Image != false && <img className = {styling.viewProject_Image} src = {`${overlayState.item.img_Url}`}/>                                        
                                    }
                                    <div style = {{width:"100%" , padding:'2%', boxSizing:'border-box'}}>
                                        {overlayState.item.Description}
                                    </div>
                                    <div style = {{height:'150px'}}/>
                                </div>

                            </div>
                        </ScrollableFrame>
                    </div>
                </div>
            }
            { // RENDER CAROSEL
                <div className= {styling.container}>
                    <div style = {{  width :'100%'}}> 
                        <div className={styling.contentContainer} >
                                <div ref = {container_Ref} className = {styling.contentWrapper} > 
                                        <div ref = {contentContainer_Ref} style ={{position:'relative', overflow :'hidden', height : `${contentContainer_Sizing.h}px`, width : `${contentContainer_Sizing.w}px`, transition :'transform 1s'}} >
                                            <div ref ={contentWrapper_Ref} className = {styling.contentContainer} style = {{padding:'0'}}>
                                                    {
                                                        (() => {
                                                            const listItems = [];
                                                            for (let i = 0; i < props.Items.length; i++) {
                                                                listItems.push(
                                                                    <div className={styling.contentItems} key ={i} style = {{  width:`${contentSize}px`, height :`${contentSize}px`, paddingLeft:`${(i % Horizontal_ElementSize.current == 0 ) ?  "0" : {spacing}}%`, paddingRight:`${spacing/2}%`, paddingTop:`${spacing}%`}}> 
                                                                        {/* <div className= {styling.elementContainer} style ={{ position:"relative", backgroundImage : `url(${props.Items[i].img_Url}`}}  */}
                                                                        <div className= {styling.elementContainer} style ={{ position:"relative"}} 
                                                                            onMouseDown={ (event : React.MouseEvent) => { Toggle_CodeWindow (event, "open",  Horizontal_ElementSize.current, props.Items, CodeWindow_Elements.windowStates, overlayState_Wrapper)} } data-key ={i}  > 
                                                                                <div style = {{position:"absolute", height:"100%", width:"100%", zIndex:'0'}}>
                                                                                    <ImageLoader url={props.Items[i].img_Url}/>
                                                                                </div>
                                                                            {

                                                                                props.Items.at(i)?.tag != undefined &&
                                                                                <div style = {{position:"absolute", zIndex:"5", left:"1%", top:"1%", fontSize:"13px",  display:"flex", flexDirection:'column',  width:"100%"}}> 
                                                                                    {
                                                                                        props.Items.at(i)!.tag?.map(
                                                                                            (tag : String) =>{
                                                                                                return (
                                                                                                    <>
                                                                                                        <div style = {{ display:"flex", }}>
                                                                                                            <p style = {{background:"red", border:"0.5px solid rgba(0,0,0,0.5)", display:'inline', color:"white",  boxSizing:"border-box", padding:"3px", paddingLeft:"5px", paddingRight:"5px", borderRadius:"5px", margin:"1px"}}>{tag}</p> 
                                                                                                        </div>
                                                                                                    </>
                                                                                                )
                                                                                            }
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            }
                                                                            <div className={styling.titleContainer} > {props.Items[i].Subject} </div>
                                                                            <div className = { styling.ElementInformationContainer }>
                                                                                <div style = {{position:'relative', display:'flex', flexDirection:'column', width:'100%', height :'100%', /* gridTemplateRows :'15% 60% 15%', */ gap :'5%', fontSize :`${globalFontSize}px`} }> 
                                                                                    {/* <div className= {styling.Element_Title} style = {{background:"blue", width:"100%",}} > */}
                                                                                        { 
                                                                                            // Dynamic FontSize Initialisor
                                                                                            i==0 &&
                                                                                            <div style = {{width:'100%', height:'20%', position:'absolute', opacity:'0', visibility:"hidden" }}>
                                                                                                <DynamicText Text={"A"} textRef = {Dynamic_globalFontRef}  onChangeAction = {dynamicFontChange_Handler}/>
                                                                                            </div>
                                                                                        }
                                                                                    {/* </div> */}
                                                
                                                                                    {/* <div className= {styling.Element_Description} style = {{position:'relative', width :'100%', height:"50%",  background:"pink", whiteSpace:'normal'}}> */}
                                                                                    <p className={styling.SingleLine}  style = {{ letterSpacing:"0px", padding:"0", margin:"0", marginBottom:"3px", fontSize :`${globalFontSize}px`, color:"white", fontWeight:"700", textDecoration:"underline"}}>
                                                                                        {/* Learn More */}
                                                                                        {props.Items[i].Subject}
                                                                                    </p> 
                                                                                    
                                                                                    {/* <div className={styling.titleContainer} > {props.Items[i].Subject} </div> */}
                                                                                    


                                                                                    <ScrollableFrame customStyle={{flex:1}} showOverflowNotification = {true}>
                                                                                        {/* {props.Items[i].Description} */}
                                                                                        <div className={styling.Element_Description} style = {{boxSizing:"border-box"}}>
                                                                                            {props.Items[i].Description}
                                                                                            {/* <p style = {{color:"white", fontFamily :"Arial,sans-serif",margin:"0", padding:"0", fontSize :`${globalFontSize}px`}}> Hello world! </p> */}
                                                                                        </div>
                                                                                     
                                                                                    </ScrollableFrame>
                                                                                    {/* <div style = {{height:"15px", background:"green"}}/> */}
                                                                                    {/* <div style = {{width:"100%", flex:1, overflowY:'scroll'  }}>
                                                                                    
                                                                                        {props.Items[i].Description}
                                                                                        <ScrollableFrame showOverflowNotification={true}>

                                                                                            {props.Items[i].Subject} 
                                                                                            {props.Items[i].Subject} 
                                                                                            {props.Items[i].Subject} 
                                                                                            {props.Items[i].Subject} 
                                                                                            {props.Items[i].Subject} 

                                                                                        </ScrollableFrame>

                                                                                        <ScrollableFrame showOverflowNotification = {true}> 
                                                                                            <div  className= {styling.Element_Description} style = {{padding:"2%", boxSizing:"border-box", background:"red"}}>
                                                                                                {props.Items[i].Description}
                                                                                            </div>
                                                                                        </ScrollableFrame>                                                                    
                                                                                    </div> */}
                                                                                    {/* </div> */}
                                                                                    {/* <div className={styling.Element_LearnMore}>
                                                                                        <p style = {{padding:"0", margin:"0", fontSize :`${globalFontSize}px`}}>
                                                                                            Learn More
                                                                                        </p>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            return listItems;
                                                        })()
                                                    }
                                            </div>
                                        </div>
                                </div >
                        </div>
                        <div id = "viewMoreContent_Container" style ={{width:'100%', display:"flex",overflow :'hidden',  minHeight:'30px', marginTop:'1%'  }}>
                            {/* <p> hasm ={ ((currentRow + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) ? "yes" : "no" }</p> */}
                            <div className = {styling.viewMore_Container} 
                                 style = {{
                                            display:"grid",
                                            gridTemplateColumns:`${ ( ((currentRowWrapper.item + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) &&  currentRowWrapper.item > 0) ? "50% 50%" : "100%" }`
                                        }}>
                                {
                                    ((currentRowWrapper.item + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) &&
                                    <div className={styling.button_boxcontainer}  
                                        style = {{  justifyContent:`${ ( ((currentRowWrapper.item + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) &&  currentRowWrapper.item > 0) ? "right" : "center" }`}} 
                                        onMouseDown = { (event:React.MouseEvent) => {viewMore_Handler(event, "forward")} }   
                                        
                                    > 
                                        {/* view more  */}
                                        {/* <img src = "./images/myresume_Content/downArrow.png" /> */}
                            
                                        {/* <h1> + </h1> */}


                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="30px" height="30px" viewBox="0 0 32 32" version="1.1">
                                            <title>down</title>
                                            <path d="M11.125 16.313l7.688-7.688 3.594 3.719-11.094 11.063-11.313-11.313 3.5-3.531z"/>
                                        </svg>
                                    </div>  
                                }
                                {
                                    currentRowWrapper.item > 0 &&
                                    <div className={styling.button_boxcontainer} 
                                        style = {{ justifyContent : `${ ( ((currentRowWrapper.item + Math.floor(contentContainer_Sizing.h / contentSize)) * Horizontal_ElementSize.current < total_elementSize) &&  currentRowWrapper.item > 0) ? "left" : "center" }` }}
                                        onMouseDown ={ (event:React.MouseEvent) => {viewMore_Handler(event,"backward")} } 
                                    > 
                                        {/* view Less  */}
                                        {/* <img src = "./images/myresume_Content/upArrow.png" /> */}
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="30px" height="30px" viewBox="0 0 32 32" version="1.1">
                                            <title>up</title>
                                            <path d="M11.25 15.688l-7.656 7.656-3.594-3.688 11.063-11.094 11.344 11.344-3.5 3.5z"/>
                                        </svg>

                                        {/* <h1> - </h1> */}
                                    </div>  

                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}



export default ContentCarosel;