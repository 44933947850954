import ScrollableFrame from '../../BaseProject/Components/layouts/Others/ScrollableFrame';
import { ScrollBarElement } from "./ScrollBarMenu";
import React, { ReactElement, useDebugValue, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { idText, JsxElement } from "typescript";
import myBrowser from "../../BaseProject/Handlers/myBrowser";
import DynamicHeadinf from "../../BaseProject/Components/layouts/Others/dynamicText";
import {ObjectRef} from '../../BaseProject/Interfaces/RefObjectInterface';
import Styling from './scrollBarStyling.module.css';
import { captureRejections } from "stream";
import TextRef from "../../BaseProject/Components/TextReference/TextReference";
import ExpandButton from "../Buttons/ExpandButton/Expand_Button";




/* ------------------------------------------ Component Description ------------------------------------------
    BASE CLASS FOR SCROLLBARMENU (NOT FOR DIRECTLY USAGE, use via SCROLLBARMENU.tsx parent wrapper component)


    Renders a scrollBarMenu fixed at the top of the browser window used to showcase the users current location 
    on the page by means of the pre-defined visual indicator (bar), and the main contents of the page.  The 
    component renders the fixed menu, and the spacing offset of the fixed menu as a relative div object to
    parent component representing the height of this menu.
    
    This ScrollBar inherits 100vw of the browser window, with height statically allocated by var 
    'scrollBarHeight' in this component. 

    The component takes as props the ScrollBarElement = { id : React.RefObject<HTMLDivElement> , name : string} 
    Object, where name = The title to be displayed in the scrollBarMenu AND id = useRef() pointer to some 
    pre-establish HTMLDivElement_Reference within the parenting scope.  The actual DOM_ID of this element 
    is also expected to be intialised for full functionality of this component (i.e. mouseEvent).

    Note (Important) :     
        1. The elements recieved by props, mainly ScrollBarElement.id is presumed to be rendered on the DOM 
        when evaluated by this compoonent, hence the parent component should re-render upon the mounting 
        of elements to the DOM.

        2. The element targeted by the scrollbar elements must have a valid 'id' tag and must contain some element
        for mouse-click to operate correctly.
        

    Arguments by props :
        <React.MutableRefObject<ScrollBarElement[]> : To ensure the most updated version (elements) without having to rerender, 
        we utilise the useRef hook to create a mutable reference to the scrollbar_Elements array. 
        (i.e. const scrollbarElementsRef = useRef(scrollbar_Elements);)
            <>.current.id : React.RefObject<HTMLDivElement> , reference by react.useRef to the section container/start 
                 of section.
            <>.current.name : String, name attribute to be displayed on scrollBar
        
    Declaration Examples : 
        TMLDivElement Declaration Example :
            <div 
                id = {"overview"} 
                ref = {scrollbarelements[0].id} 
                style = { {width : '100vw', height : '100vh', backgroundColor :'white', position :'relative'} 
            }> 
        ScrollBarElement Declaration Example :
             let scrollbarelements : ScrollBarElement[]= [
                {
                    id : useRef<HTMLDivElement>(null),
                    name : "overview"
                },
            ]
------------------------------------------------------------------------------------------------------------*/




let ScrollBarElementOffset = 0; // = 1 / (props.elements.length-1) be the global element offset value.
let ScrollBarZIndex = 550;
let prevElement : HTMLParagraphElement | undefined = undefined;
let currentElement : HTMLParagraphElement | undefined = undefined;
let activeElementColor = "black";
let inActiveElementColor = "grey";
let Scroll_Pos:{pos:number, direction: String} = {pos:0, direction:""};



let Get_IndicatorValue = (elements : ScrollBarElement[] , elementRef : React.MutableRefObject<HTMLParagraphElement>[]) : number =>
{
    if (elementRef.length != elements.length) {
        console.error("ELEMENT REF LENGTH = " + elementRef.length + " element length = " + elements.length + ". Inconsistency!");
        return 1;
    }
    if (elements[0].reference.current != null)
    {
        let pageHeight : number = myBrowser.getPageHeight();
        let Scroll_State : number = myBrowser.getWindowCenter();
        
        if (Scroll_State < 0 || myBrowser.getWindowTop() === 0) return 0;
        

        for (let i =0; i <= (elements.length-2); i++)
        {
            let ElementPagePos : number = myBrowser.RefHandling.getRefLocationStart(elements[i].reference); 
            let NextElementPagePos : number = myBrowser.RefHandling.getRefLocationStart(elements[i+1].reference);

            if ( (Scroll_State >= ElementPagePos) && (Scroll_State < NextElementPagePos))
            {
                let separateScroll_State = Scroll_State - ElementPagePos; 
                let separateElement_End = NextElementPagePos - ElementPagePos;
                let separateScrollPercentage = separateScroll_State / separateElement_End;
                let indicator = (i * ScrollBarElementOffset ) + ( ScrollBarElementOffset * separateScrollPercentage );
                currentElement = elementRef[i].current;
                return indicator;
            }
        }
    }

    currentElement = elementRef[elementRef.length-1].current;
    return 1;
}

let elementClickEvent = (event : React.MouseEvent<HTMLDivElement>, ScrolBarElements : ScrollBarElement[] ) =>
{
    myBrowser.scrollToPage(0, myBrowser.RefHandling.getRefLocationStart(ScrolBarElements[parseInt(event.currentTarget.id)].reference));
}


let scrollTo = (element :ScrollBarElement ) => {
    if (element.reference.current != undefined){
        let itemLocation = myBrowser.RefHandling.getRefPageLocationY(element.reference);
        if (itemLocation != undefined)  myBrowser.scrollToPage(0, itemLocation );
    }
}


let indicator:number = 0;
let RenderScrollBar : React.FC<{ ScrolBarElements :  React.MutableRefObject<ScrollBarElement[]>, isMobile :boolean, starting_properties ?: React.CSSProperties, non_Starting_properties ? : React.CSSProperties , hasOffset:boolean}> = (props) => 
{
    let animationBar = useRef<HTMLDivElement | null> (null);
    let elementTextRef = useRef<React.MutableRefObject<HTMLParagraphElement>[]>([]);
    let offsetDivRef = useRef<HTMLDivElement> (null);
    let scrollBarRef = useRef<HTMLDivElement> (null);
    let headerContainer_Ref = useRef<HTMLDivElement>(null);
    let [isMobileMenu, set_isMobileMenu] = useState<boolean>(false);
    let animationBarContainer = useRef<HTMLDivElement>(null);    
    ScrollBarElementOffset = 1 / (props.ScrolBarElements.current.length-1); //intiial scrollbar offset
    // Rescale scrollBar element font-size s.t. x.font-size() = x'.fontSize() ∀ x ∈ props.ScrolBarElements.
    let [tabElement, set_TabElement] = useState<JSX.Element[] | undefined>(undefined);

    //** HANDLE RESIZE & SCROLL */
    useLayoutEffect(
        () =>{
            let resize = () => {
                scaleOffset();
            }
            
            let timeoutId: NodeJS.Timeout;

            const handleResize = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => resize(), 500); // Adjust the delay as needed (e.g., 300 milliseconds)
            };


            window.addEventListener("resize", handleResize)
            document.addEventListener("scroll", animateScrollBar);
            scaleOffset();

            return () => {
                window.removeEventListener("resize", handleResize)
                document.removeEventListener("scroll", animateScrollBar);
            }
        },[]
    );



    let GenerateRenderElements = (ScrolBarElements : React.MutableRefObject<ScrollBarElement[]> , elementRef :  React.MutableRefObject<HTMLParagraphElement | null>[] ) : JSX.Element[] => 
    {
        let toRet : JSX.Element[] = [];

        let ElementReferences : ObjectRef<HTMLParagraphElement> = 
        {
            setFunc : (ref: ObjectRef<HTMLParagraphElement>, toRef: React.RefObject<HTMLParagraphElement>) : void => 
                        { 
                            ref.ref = toRef;              
                            elementRef.push(toRef);      
                        },
            ref : useRef(null)
        }


        for (let i =0, lastElement = ScrolBarElements.current.length-1; i < lastElement; i++)
        {
            toRet.push ( 
                <>
                    <div style = {{display : 'flex',  flexDirection :"column", width:'100%', height :'100%', position:'relative', boxSizing:'border-box'}}> 
                            {/* <p>{ScrolBarElements.current[i].name}</p> */}
                            <div className = {Styling.ScrollBarTitles} style = {{ zIndex :`${ScrollBarZIndex}`}}>  
                                <div onMouseDown={() => { scrollTo(ScrolBarElements.current[i])}} className={Styling.TitleContainer} >
                                    <TextRef text={`${ScrolBarElements.current[i].name}`} customStying={{ marginBottom:'8px'}} textRef = {ElementReferences} />
                                    {/* <p className="TitleText"> {ScrolBarElements.current[i].name}</p> */}
                                </div> 
                            </div>  
                            {   
                                (i == (lastElement-1))  &&
                                <div style = {{  display :'flex', width :'100%', height : '100%', position:'absolute', zIndex:'10', justifyContent :'flex-end'}}>  
                                    <div className = {Styling.ScrollBarTitles} style ={ {transform : "translate(50%, 0px)",  width :'100%', height :'100%'}} >  
                                        <div className={Styling.TitleContainer} onMouseDown={() => {scrollTo(ScrolBarElements.current[i+1])}} >
                                            <TextRef text={`${ScrolBarElements.current[i+1].name}`} customStying={{marginBottom:"8px"}}  textRef = {ElementReferences} />
                                            {/* <p className="TitleText"> {ScrolBarElements.current[i].name}</p> */}
                                        </div>
                                    </div> 
                                </div> 
                            }  
                    </div>
                </>
            );
        }

        return toRet ;
    }


    const scrollVal = useRef<number[]>([0, 0, 0]);      // where scrolval[2] = currentscroll index; Used in animateScrollBar funct.
    let [scrollDirection, set_ScrollDirection] = useState<String>("up");
    
    // Animatate the scrollBar baesd on the indicator value
    let animateScrollBar = () =>
    {

        if (animationBar.current != null && elementTextRef )
        {
            console.log("DETERMINING BAR")
            if (props.ScrolBarElements.current.length > 0) {
                if (props.ScrolBarElements.current[0].reference.current === null)     indicator = 0;
                else   {

                    indicator = Get_IndicatorValue(props.ScrolBarElements.current, elementTextRef.current);                            
                    console.log("GETTING INDICATOR VALUE = "+ indicator);
                } 
            } else {
                return; /* We return on parentElement render on DOM = false.  */
            }

            // ANIMATE BAR
            animationBar.current.style.width = `${indicator*100}%`
            if (currentElement != undefined)
            {
                console.log("Animating BAR")

                if (currentElement != prevElement)
                {
                    currentElement.style.color =  (indicator === 0) ? inActiveElementColor : activeElementColor;
                    if (prevElement != null) prevElement.style.color = inActiveElementColor;
                    prevElement = currentElement;
                }
            }
        }

        // if (myBrowser.getWindowTop())
        // console.log("WINDOW TOP = " +myBrowser.getWindowTop());
        if (props.isMobile){
            if (myBrowser.getWindowTop() > Scroll_Pos.pos){
                // console.error("SCROLLING DOWN");
                // console.log("prev pos  = " + Scroll_Pos.pos   + " NOW DIRECTION : " + myBrowser.getWindowTop());
    
                Scroll_Pos.pos = myBrowser.getWindowTop();
                if (Scroll_Pos.direction != "down"){
                    Scroll_Pos.direction = "down";
                    set_ScrollDirection("down");
                }
                
    
            }else if (myBrowser.getWindowTop() < Scroll_Pos.pos){
                // console.error("SCROLLING UP")
                Scroll_Pos.pos = myBrowser.getWindowTop();
                if (Scroll_Pos.direction != "up"){
                    Scroll_Pos.direction = "up";
                    set_ScrollDirection("up");
                }
                
    
            }

        }


        // Other animation
        if (headerContainer_Ref.current != null && containerRef.current != undefined
            && animationBarContainer.current != undefined && scrollBarRef.current != undefined
            && logoContainer.current != undefined && tabContainer.current != undefined)
        {
            if (myBrowser.getWindowTop() === 0){
                // AT TOP OF PAGE
                // headerContainer_Ref.current.style.transform = "scale(0.5)";
                // headerContainer_Ref.current.style.boxShadow = "none";
                // headerContainer_Ref.current.style.borderRadius = "0"; 
                containerRef.current.style.gridTemplateColumns = "60% 40%";
                animationBarContainer.current.style.opacity = '0';
                scrollBarRef.current.style.width = "65%";
                logoContainer.current.style.opacity = "1";
                tabContainer.current.style.justifyContent = "flex-end";
                containerRef.current.style.boxShadow = "none";

                Object.assign(headerContainer_Ref.current.style, props.starting_properties);
            }else{
                // NOT AT TOP OF PAGE
                let currentScroll = scrollVal.current;
                Object.assign(headerContainer_Ref.current.style, props.non_Starting_properties);
                currentScroll[ currentScroll[2] ] = myBrowser.getWindowTop();
                containerRef.current.style.gridTemplateColumns = "0% 100%";
                animationBarContainer.current.style.opacity = '1';
                scrollBarRef.current.style.width = "90%";
                logoContainer.current.style.opacity = "0";
                tabContainer.current.style.justifyContent = "center";
                containerRef.current.style.boxShadow = "rgba(0, 0, 0, 0.05) 0px 25px 20px -20px";
                // headerContainer_Ref.current.style.transform = "scale()";
                // headerContainer_Ref.current.style.borderRadius = "2em";
                // headerContainer_Ref.current.style.boxShadow = "rgba(0, 0, 0, 0.45) 0px 25px 20px -20px";
 
                // if (currentScroll[ (currentScroll[2]+1)%2 ] < currentScroll[currentScroll[2]]){
                //     // SCROLLING DOWN
                //     // headerContainer_Ref.current.style.transform = "scale(0.5)"; 
                    
                // }else{
                //     // SCROLLING UP
                //     // headerContainer_Ref.current.style.transform = "scale(0.55)"; 
                // } 
    
                currentScroll[2] = (currentScroll[2] +1 )%2;
            }
        }
    };

    let scaleScrollBar = () =>{
        if (scrollBarRef.current != null){

            // scale bar position
            let translateDistance = myBrowser.DomHandling.getCSSProperty('.ScrollBarTitles', 'transform')?.split(',')[4]; 
            let current_ScrollBarLoc = myBrowser.RefHandling.getRefLocationX(scrollBarRef);
            let i =0;
            let currentWidth : number = parseInt(scrollBarRef.current.style.width.replace("%",""));

            if (translateDistance != undefined){
                while (current_ScrollBarLoc < (-parseInt(translateDistance) ) && i++ < 100 )
                {
                    scrollBarRef.current.style.width = `${currentWidth - i}%`
                    current_ScrollBarLoc = myBrowser.RefHandling.getRefLocationX(scrollBarRef);
                }
            }
        }
    };

    useLayoutEffect( 
        () => { 
            scaleScrollBar();
            animateScrollBar();

            if (isMobileMenu){
                // mobile menu opened
                myBrowser.lockPageScroll();
            }else{
                    // mobile menu closed
                myBrowser.unlockPageScroll();
            }
        }
    );

    // scale offset div
    let scaleOffset = () => { 
        if (offsetDivRef.current != undefined && headerContainer_Ref.current != undefined){
            offsetDivRef.current.style.height = `${ headerContainer_Ref.current.clientHeight.toLocaleString() }px` ;
        }

    }

    let headerHover = (event : React.MouseEvent) => {
        let target = event.currentTarget as HTMLDivElement;
        
        // if (headerContainer_Ref.current != undefined && myBrowser.getWindowTop() != 0)  {
        //     if (event.type === 'mouseover')     headerContainer_Ref.current.style.transform = "scale(0.55)"; 
        //     else    headerContainer_Ref.current.style.transform = "scale(0.5)";  // assumed
        // } 
    } 

    let offsetLeft = useRef<HTMLDivElement>(null);
    let containerRef = useRef<HTMLDivElement>(null);
    let logoContainer = useRef<HTMLDivElement>(null);
    let tabContainer = useRef<HTMLDivElement>(null); 




    // custom styling
    const PageQuery : String = `
        @media only screen and (max-width: ${myBrowser.mobile_Width()}px) {
            #header_Container{
                position: fixed;
                left:0;
                right:0;
                background:none;
                
            }

            #Top_HeaderContainer{
                width: 100%;
            }

        }

        @media only screen and (min-width: ${myBrowser.mobile_Width()}px) {
            #header_Container{
                position: sticky;
                background:white;
            }
            
            #Top_HeaderContainer{
                width: 90%;
            }
        } 
    `;


    return(
        <>   
            <style> {PageQuery} </style>
            { props.hasOffset && <div ref = {offsetDivRef} style = {{height:"0px"}}/> }

            {
                // MOBILE MENU BACKGROUND CLOSE
                // isMobileMenu && <div className="closingBackgroundOverlay" onMouseDown={() => {set_isMobileMenu(false)}} style = {{zIndex:`${ScrollBarZIndex-1}`}}/>
                                
            }
            <div id = "header_Container" ref = {containerRef}  className= {Styling.headerContainer} style = {{ boxShadow:"none",  gridTemplateColumns:'0% 100%', width:`${props.isMobile ?  "100%" : "103%"}`}}>
                <div ref ={logoContainer} style = {{width:'100%', height :"100%", display:"flex",  paddingLeft:"5%", alignItems:'center',  overflow:"hidden", transition:'opacity 0.5s'}}>
                    <h1 style ={{marginRight:'5px', fontSize:'45px', padding:'0', margin:'0'}}> G </h1> 
                </div>
                <div ref = {tabContainer} style = {{width :"100%", display:'flex', justifyContent:'center', alignContent:'center', height:'100%', alignItems:'center' }}>
                    <div id = "Top_HeaderContainer"  onMouseOver={headerHover} onMouseOut={headerHover} ref = {headerContainer_Ref} className={Styling.topHeaderContainer} style = { {zIndex :`${ScrollBarZIndex}` }}> 
                        {
                            // MOBILE TAB
                            props.isMobile && 
                            <>
                            {
                                // !isMobileMenu &&
                                <div className={Styling.mobileContainer} style = {{ background:`${isMobileMenu ? "white" : "none"}`, padding :`${isMobileMenu ? "5%" : "2%"}` ,  transition:"all 1s"}}>
                                    <div onMouseDown={() => {set_isMobileMenu(true)}} style = {{transition:"all 1s", display:'flex', width:'100%', height:"100%",  paddingLeft:`${isMobileMenu ? "0%" : "100%"}`}}>
                                        {/* <h2 style ={{transform: `translate(${ isMobileMenu ? "10%" : "-50%" }, 0)`, fontSize:'25px'}}>  */}
                                            { !isMobileMenu && 
                                                <div style = {{width:'50px', height:'50px', transition:'all 0.5s',  transform:'translate(-80%,0%)', opacity:`${scrollDirection === "up" ? "1" : "0"  }`}}>
                                                    <ExpandButton/>
                                                </div>
                                                || 
                                                <div onMouseDown={(event) => { event.stopPropagation(); set_isMobileMenu(false);}}>
                                                    <svg  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="5vw" height="5vw" viewBox="0 0 50 50">
                                                        <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"></path>
                                                    </svg> 
                                                </div> 
                                            }
                                        {/* </h2> */}
                                    </div>
                                    <div style = {{ background:"white", maxHeight:`${isMobileMenu ? "90vh" : "0px"}`, minWidth:'150px', overflow:'hidden', transition:`${isMobileMenu ? "max-height 1.5s ease-in-out" : "all 1.5s"}`, paddingTop:`${isMobileMenu ? "2%" : "0"}`}}>
                                            <ScrollableFrame>
                                                <div className={Styling.mobileItemContainer} >
                                                    {
                                                        (
                                                            () => {
                                                                let k : ReactElement[] = []
                                                                let ScrolBarElements = props.ScrolBarElements.current;
                                                                for (let i=0; i < ScrolBarElements.length; i++)  
                                                                    k.push (<p onMouseDown={() => { scrollTo(ScrolBarElements[i]); set_isMobileMenu(false)}} className={Styling.mobileItem} style = {{ borderBottom: `${ i != ScrolBarElements.length-1 ? "0.2px solid black" : ""} ` }}> {ScrolBarElements[i].name}  </p>);
                                                                return k;
                                                            }
                                                        )()
                                                    }
                                                </div>
                                            </ScrollableFrame>
                                            
                                    </div>
                                </div> 
                            }
                            </>
                            ||
                            // DESKTOP TAB
                            <div className={Styling.headerElement_Container}  ref = {scrollBarRef}  style = { { position:'relative'}}>                    
                                <div style = { {width : '100%'}}> 
                                    <div style = { {width : '100%',  height :'100%', display :'grid', gridTemplateColumns :`repeat(${props.ScrolBarElements.current.length-1}, ${(ScrollBarElementOffset)*100}%)`, position :'relative' }}> 
                                        {GenerateRenderElements(props.ScrolBarElements, elementTextRef.current)}
                                    </div> 
                                </div>

                                <div style = {{display:'flex', justifyContent:'center'}}>
                                    <div ref = {animationBarContainer} style = { { transition:"all 1s", borderRadius :'2em', width : '130%', height :'1px', backgroundColor :'rgba(0,0,0,20%)', position :'absolute', zIndex:'999', bottom: '0', }}>
                                        <div ref = {animationBar} style = {{ transition :'all 0.1s',  height : '100%', position :'relative', backgroundColor : 'black', borderRadius:'2em'}}/>
                                    </div> 
                                    <div style = { { width : '130%', height :'100%',  position :'absolute', background:'white', bottom: '0',zIndex:'1' }}/>
                                </div>
                            </div>


                        }
                    </div>

                </div>
                
            </div> 
        </>
    )
}

export default RenderScrollBar;